@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  * {
    @apply border-border;
  }
  
  :root {
    --radius: 0.5rem;
    --toolkit-input-background: 58 62 69;
  }

  body {
    @apply bg-background text-foreground;
  }

  .card {
    @apply shadow-lg duration-150 ease-in hover:scale-105 hover:shadow-lg;
  }

  .w-narrow {
    @apply m-auto mx-auto w-full px-6 md:max-w-full md:px-6 lg:md:px-6 lg:max-w-full xl:max-w-7xl xl:px-0;
  }

  .frame {
    @apply rounded-md border;
  }
  .shadow-right {
    box-shadow: 5px 0px 10px #00000069;
  }

  .text-2 {
    @apply text-neutral-600 dark:text-neutral-300;
  }

  .menu {
    @apply absolute -left-8 top-8 w-[480px] rounded-md border bg-neutral-100 p-2 shadow-lg dark:bg-neutral-700;
  }

  .search-bar {
    @apply flex w-full min-w-[280px] flex-row items-center justify-start rounded-md border bg-neutral-100 px-0 py-2 dark:bg-neutral-700;
  }

  .search-bar input {
    @apply bg-transparent p-0 px-1 outline-none;
  }
  .menu .item {
    @apply cursor-pointer px-3 py-2;
  }

  .menu .item-highlighted {
    @apply rounded-md bg-neutral-200 dark:bg-neutral-600;
  }

  .icon {
    @apply h-8 w-8 rounded p-2 hover:bg-gray-100 dark:hover:bg-neutral-700;
  }

  a {
    @apply text-xs text-blue-500 hover:underline;
  }

  .hide-scroll {
    -ms-overflow-style: none; /* Internet Explorer 10+ */
    scrollbar-width: none; /* Firefox */
  }

  .hide-scroll::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }

  .remove-tailwind * {
    @apply all-unset;
  }

  .markdown {
    @apply w-full break-normal text-sm leading-normal;
  }
  
  .markdown h1,
  .markdown h2,
  .markdown h3,
  .markdown h4,
  .markdown h5,
  .markdown h6 {
    @apply mb-4 mt-6 font-semibold leading-3 text-foreground;
  }
  
  .markdown a:active,
  .markdown a:hover {
    text-decoration: underline;
    outline-width: 0;
  }
  .markdown a:not([href]) {
    color: inherit;
    text-decoration: none;
  }
  
  .markdown strong {
    @apply font-semibold;
  }
  
  .markdown img {
    @apply box-content max-w-full border-none bg-transparent;
  }
  
  .markdown img[align='right'] {
    @apply pl-5;
  }
  
  .markdown img[align='left'] {
    @apply pr-5;
  }
  
  .markdown code,
  .markdown kbd,
  .markdown pre {
    @apply font-mono font-medium;
  }
  
  .markdown code {
    @apply m-0
    rounded-sm bg-secondary/5 px-2 pt-1
    font-mono text-xs text-rose-400 dark:text-rose-300;
  }
  
  .markdown pre > code {
    @apply m-0 whitespace-pre break-normal border-0 bg-transparent p-0;
  }
  
  .markdown pre code {
    @apply m-0 inline
    max-w-fit overflow-visible p-0;
  }
  
  .markdown hr {
    @apply my-6 box-content h-1
    overflow-hidden border-b
    border-b-secondary bg-secondary p-0;
  }
  
  .markdown hr::before {
    display: table;
    content: '';
  }
  
  .markdown hr::after {
    display: table;
    clear: both;
    content: '';
  }
  
  .markdown input {
    font: inherit;
    margin: 0;
    overflow: visible;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
  }
  
  .markdown input[type='checkbox'] {
    box-sizing: border-box;
    padding: 0;
  }
  
  .markdown * {
    box-sizing: border-box;
  }
  
  .markdown h1 {
    @apply mx-0 mt-3 border-b pb-1 text-xl;
  }
  
  .markdown h2 {
    @apply border-b pb-1 text-lg;
  }
  
  .markdown h3 {
    @apply border-b pb-1 text-lg;
  }
  
  .markdown h4 {
    @apply border-b pb-0.5 text-base;
  }
  
  .markdown h5 {
    @apply text-sm;
  }
  
  .markdown h6 {
    @apply text-xs;
  }
  
  .markdown p {
    @apply mb-2.5 mt-0;
  }
  
  .markdown blockquote {
    @apply m-0;
  }
  
  .markdown ul {
    list-style-type: disc;
  }
  
  .markdown ol {
    list-style-type: decimal;
  }
  
  .markdown ul,
  .markdown ol {
    @apply py-0 pl-0;
  }
  
  .markdown ol ol,
  .markdown ul ol {
    list-style-type: lower-roman;
  }
  
  .markdown ul ul ol,
  .markdown ul ol ol,
  .markdown ol ul ol,
  .markdown ol ol ol {
    list-style-type: lower-alpha;
  }
  
  .markdown dd {
    @apply ml-0;
  }
  
  .markdown pre {
    @apply my-0
    overflow-auto rounded
    bg-secondary/5 p-4
    font-mono text-xs leading-3;
  }
  
  .markdown::before {
    display: table;
    content: '';
  }
  
  .markdown::after {
    display: table;
    clear: both;
    content: '';
  }
  
  .markdown p,
  .markdown blockquote,
  .markdown ul,
  .markdown ol,
  .markdown dl,
  .markdown table,
  .markdown pre {
    @apply mb-4 mt-0 text-secondary-foreground;
  }
  
  .markdown blockquote {
    @apply border-l border-l-secondary px-4 py-0 text-accent;
  }
  
  .markdown blockquote > :first-child {
    @apply mt-0;
  }
  
  .markdown blockquote > :last-child {
    @apply mb-0;
  }
  
  .markdown kbd {
    @apply inline-block rounded-sm
    border border-border bg-muted-foreground px-1.5 py-0.5 align-middle font-mono text-[11px]
    leading-3 text-accent shadow;
  }
  
  .markdown ul,
  .markdown ol {
    @apply pl-8;
  }
  
  .markdown ul ul,
  .markdown ul ol,
  .markdown ol ol,
  .markdown ol ul {
    @apply my-0;
  }
  
  .markdown li {
    word-wrap: break-all;
  }
  
  .markdown li > p {
    @apply mt-4;
  }
  
  .markdown li + li {
    @apply mt-1;
  }
  
  .markdown dl {
    @apply p-0;
  }
  
  .markdown dl dt {
    @apply mt-4 p-0 text-base font-semibold italic;
  }
  
  .markdown dl dd {
    @apply px-4 pt-0;
  }
  
  .markdown table {
    @apply w-full border-collapse border-spacing-0 overflow-auto border-none;
  }
  
  .markdown table th,
  .markdown table td {
    @apply px-3 py-1;
  }
  
  .markdown table th {
    @apply whitespace-nowrap border-b-2 border-border bg-secondary/10 text-left font-semibold;
  }
  
  .markdown table tbody tr {
    @apply border-t border-border hover:bg-muted/40;
  }
  
  .markdown table tbody tr:nth-child(2n) {
    @apply border-t hover:bg-muted/40;
  }
  
  em {
    font-style: italic;
  }
  
  .markdown > *:first-child {
    @apply mt-0;
  }
  
  .markdown > *:last-child {
    @apply mb-0;
  }
  

  * {
    scrollbar-width: thin;
    scrollbar-color: var(--secondary) var(--primary);
  }

  /* Override the default react-color input background (HEX, rgb, etc...) */
  [id*='rc-editable-input'] {
    @apply bg-white text-gray-900 !important;
  }

  /* Chrome, Edge, and Safari */
  *::-webkit-scrollbar {
    background-color: transparent;
    border: 0px;
    width: 8px;
  }

  *::-webkit-scrollbar-track {
    border-radius: 8px;
    width: 8px;
    background-color: transparent;
  }

  *::-webkit-scrollbar-thumb {
    border-radius: 8px;
    width: 8px;
    background-color: hsl(var(--secondary));
  }
}
